import React, {useState} from 'react';
import {
	Modal, ModalOverlay, ModalContent, ModalFooter, ModalBody, ModalCloseButton,
	Button,
	Text,
	Heading,
	Box,
	Icon,
	Spinner,
	useToast,
	Flex,
	VStack
} from '@chakra-ui/react';
import { ArrowDownIcon } from '@chakra-ui/icons'
import {StudyEntity} from 'Models/Entities';
import {useQuery} from "@apollo/client";
import {StudyTable} from "Views/Components/Tables/StudyTable"
import axios from "axios";
import {SERVER_URL} from "../../../Constants";
import alert from "../../../Util/ToastifyUtils";

interface mergeStudyModalProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
	sourceStudy: StudyEntity | undefined;
}

export const MergeStudyModal = (props: mergeStudyModalProps) => {
	const { isOpen, onClose, onConfirm, sourceStudy } = props;
	const [hasBeenWarned, setHasBeenWarned] = useState<boolean>(false);
	const [hasContinued, setHasContinued] = useState<boolean>(false);
	const [destinationStudyId, setDestinationStudyId] = useState<string | null>(null);
	const [loading, setLoading] = useState(false);
	const toast = useToast();

	const { data, refetch } = useQuery(StudyEntity.getStudyMergeOverview(), {
		variables: {
			args: [{
				path: 'studyDate',
				comparison: 'greaterThanOrEqual',
				value: formatDateForStartOfDay(sourceStudy?.studyDate?.toString()),
			}, {
				path: 'studyDate',
				comparison: 'lessThanOrEqual',
				value: formatDateForEndOfDay(sourceStudy?.studyDate?.toString()),
			}]
		},
		fetchPolicy: 'no-cache',
	});

	const handleContinue = () => {
		if(destinationStudyId) {
			setHasContinued(true)
		} else {
			alert("Must select exactly one Destination Study", "error")
		}
	}
	
	const handleBack = () => {
		setHasContinued(false)
		setDestinationStudyId(null)
	}
	
	const handleCancel = () => {
		setHasBeenWarned(false)
		setHasContinued(false)
		setDestinationStudyId(null)
		onClose()
	}
	
	const handleSubmit = async () => {
		if(destinationStudyId) {
			let sourceId = sourceStudy?.id ? sourceStudy.id : null;
			let destinationId = destinationStudyId;
			setDestinationStudyId(null);
			setHasBeenWarned(false)
			setHasContinued(false);
			await handlePostMerge(sourceId, destinationId)
		} else {
			alert("Must select exactly one Destination Study", "error")
		}
	}
	
	const handleRefetch = async () => {
		try {
			await refetch();
		} catch (error) {
			console.error("Refetching failed: ", error)
			window.location.reload();
		}
	}
	
	const handlePostMerge = async (sourceId: string | null, destinationId: string | null) => {
		setLoading(true);

		if(sourceId && destinationId) {
			try {
				const response = await axios.post(
					`${SERVER_URL}/api/entity/StudyEntity/StudyMerge`,
					{
						SourceStudyId: sourceId,
						DestinationStudyId: destinationId,
					},
				);

				if (response.status === 200) {
					setTimeout(()=> {
						toast({
							title: "Success!",
							description: "Merge Study was successful!",
							status: "success",
							duration: 5000,
							isClosable: true,
							position: "top"
						});
						handleRefetch();
						setLoading(false);
						onConfirm()
					}, 500)
				} 
			} catch (error) {
				setTimeout(()=> {
					toast({
						title: "Error!",
						description: "An error occurred. Merge Study failed.",
						status: "error",
						duration: 5000,
						isClosable: true,
						position: "top"
					});
					handleRefetch();
					setLoading(false);
					handleCancel()
				}, 500)
			} 
		}
	}

	return (
		<Modal isOpen={isOpen} onClose={() => handleCancel()} isCentered>
			<ModalOverlay />
			<ModalContent maxH="100%" maxW="70%" className="mergeStudyModal" paddingTop="14px">
				{loading ? <>
						<ModalBody  maxHeight="80vh" overflowY="auto" w="100%">
							<Flex display="flex" justify="center" height="100%" align="center" mb={6}>
								<VStack>
									<Box mb={8}>
										<Text textAlign={"center"}>Merge in progress, please wait...</Text>
									</Box>
									<Box>
										<Spinner/>
									</Box>
								</VStack>
							</Flex>
						</ModalBody>
					</>
				: <>
						<ModalCloseButton />
						{hasContinued ? <>
							<ModalBody maxHeight="80vh" overflowY="auto" w="100%">
								<Heading as='h4' size='md' noOfLines={1} textAlign="center">WARNING</Heading>
								<Text display="flex" justifyContent="center" mt={0}>The contents of the Source Study will be merged into the Destination Study.</Text>
								<Text display="flex" justifyContent="center" mt={0}>This is a permanent action and cannot be undone. </Text>
								<Heading as='h4' size='md' noOfLines={1} textAlign="center">
									Confirm merge details:
								</Heading>
								<StudyTable
									studyEntitys={sourceStudy ? [getConvertedStudy(sourceStudy)] : []}
									caption={"Source Study"}
								/>
								<Box mt={1} mb={1} display="flex" justifyContent="center">
									<Icon as={ArrowDownIcon} boxSize={8} />
								</Box>
								<StudyTable
									studyEntitys={data?.studyEntitys.filter((study : any) => study.id == destinationStudyId) || []}
									caption={"Destination Study"}
								/>
							</ModalBody>
							<ModalFooter display="flex" justifyContent="center">
								<Button variant='outline' onClick={() => handleBack()} mr={3}>Back</Button>
								<Button colorScheme='blue' onClick={() => handleSubmit()}>Submit</Button>
							</ModalFooter>
						</> : <>
							{hasBeenWarned ?
								<>
									<ModalBody maxHeight="80vh" overflowY="auto" w="100%">
										<Text textAlign="center" mt={4}>
											The Merge Study action has been selected on the following study:
										</Text>
										<StudyTable
											studyEntitys={sourceStudy ? [getConvertedStudy(sourceStudy)] : []}
											caption={"Source Study"}
										/>

										<Text textAlign="center" mt={4}>
											Please select the study to merge with:
										</Text>
										<StudyTable
											studyEntitys={data?.studyEntitys.filter((study : any) => study.id !== sourceStudy?.id) || []}
											isSelectable={true}
											setDestinationStudyId={setDestinationStudyId}
											caption={"Destination Study"}
										/>
									</ModalBody>

									<ModalFooter display="flex" justifyContent="center">
										<Button variant='outline' onClick={() => handleCancel()} mr={3}>Cancel</Button>
										<Button colorScheme='blue' onClick={() => handleContinue()}>Continue</Button>
									</ModalFooter>
								</> :
								<>
									<ModalBody maxHeight="80vh" overflowY="auto" w="100%">
										<Heading as='h4' size='md' noOfLines={1} textAlign="center">WARNING</Heading>
										<Text textAlign="center" mt={4}>
											The Merge Study action has been selected on the following study:
										</Text>
										<StudyTable
											studyEntitys={sourceStudy ? [getConvertedStudy(sourceStudy)] : []}
											isSelectable={false}
											setDestinationStudyId={setDestinationStudyId}
											caption={"Source Study"}
										/>
										<Text textAlign="center" mt={4}>
											Merging studies is a permanent action and cannot be undone.
										</Text>
										<Text textAlign="center" mt={4}>
											Do you want to continue with the Merge Study process and select the Destination Study to merge with?
										</Text>
									</ModalBody>
									<ModalFooter display="flex" justifyContent="center">
										<Button variant='outline' onClick={() => handleCancel()} mr={3}>Cancel</Button>
										<Button colorScheme='blue' onClick={() => setHasBeenWarned(true)}>Continue</Button>
									</ModalFooter>
								</>}
						</>
						}
				</>}
			</ModalContent>
		</Modal>
	);
};

function formatDateForStartOfDay(date: string | undefined): string {
	if (!date) return '';
	const parsedDate = new Date(date);
	const year = parsedDate.getFullYear();
	const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
	const day = String(parsedDate.getDate()).padStart(2, '0');
	
	return `${year}-${month}-${day}T00:00:00`;
}

function formatDateForEndOfDay(date: string | undefined): string {
	if (!date) return '';
	const parsedDate = new Date(date);
	const year = parsedDate.getFullYear();
	const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
	const day = String(parsedDate.getDate()).padStart(2, '0');
	
	return `${year}-${month}-${day}T23:59:59`;
}

function getConvertedStudy (study : StudyEntity)  {
	return {
		patient: {
			firstName: study.patient.firstName,
			lastName: study.patient.lastName,
			name: study.patientName,
			dob: study.patient.dob.toDateString(),
			patientId: study.patient.patientId,
			site: {
				siteName: study.patient.site.siteName,
				ahiDatastoreId: study.patient.site.ahiDatastoreId
			}
		},
		studyDate: study.studyDate.toDateString(),
		studyType: study.studyType.toString(),
		studyFilesCount: study.studyFilesCount.toString(),
		id: study.id
	}
}