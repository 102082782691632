import React, { useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    TableCaption
} from '@chakra-ui/react';
import SelectableTableRow from "./SelectableTableRow";

interface studyTableProps {
    studyEntitys: {
        patient: {
            firstName: string;
            lastName: string;
            name: string;
            dob: string;
            patientId: string;
            site: {
                siteName: string;
                ahiDatastoreId: string;
            };
        };
        studyDate: string; 
        studyType: string;
        studyFilesCount: string;
        id: string;
    }[]; 
    isSelectable?: boolean;
    setDestinationStudyId?: (destinationStudyId: string | null) => void;
    caption?: string;
    size?: string;
}
export const StudyTable = (props: studyTableProps) => {
    const { studyEntitys, isSelectable, setDestinationStudyId, caption, size } = props;
    const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
    const handleRowClick = (index: number) => {
        if(setDestinationStudyId) {
            setDestinationStudyId(index !== selectedRowIndex ? studyEntitys[index]?.id : null)
        }
        setSelectedRowIndex(index === selectedRowIndex ? null : index);
    };

    return (
        <TableContainer border="solid black 1px">
            <Table size={size ? size :"md"}>
                {caption && <TableCaption placement={"top"} mt={"-1"} mb={"-2"}>{caption}</TableCaption>}
                <Thead>
                    <Tr>
                        <Th>Patient Name</Th>
                        <Th>Patient ID</Th>
                        <Th>DOB</Th>
                        <Th>Study Date</Th>
                        <Th>Study Type</Th>
                        <Th>Site</Th>
                        <Th>Images</Th>
                    </Tr>
                </Thead>
                <Tbody paddingTop="0px">
                    {studyEntitys.length > 0 ? (
                        studyEntitys.map((study, index) => (
                            <SelectableTableRow
                                key={index}
                                rowData={[
                                    getPatientName(study),
                                    study.patient.patientId,
                                    formatDateForDisplay(study.patient.dob),
                                    formatDateForDisplay(study.studyDate),
                                    study.studyType,
                                    study.patient.site.siteName,
                                    study.studyFilesCount,
                                ]}
                                isSelected={isSelectable ? index === selectedRowIndex : false}
                                onClick={() => handleRowClick(index)}
                                isSelectable={isSelectable ? isSelectable : false}
                            />
                        ))
                    ) : (
                        <Tr>
                            <Td colSpan={7} textAlign="center">
                                No studies found matching study date.
                            </Td>
                        </Tr>
                    )}
                </Tbody>
            </Table>
        </TableContainer>
    );
};

function formatDateForDisplay (dateString: string | undefined) {
    if (!dateString) return ''
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}

function getPatientName(study : any) {
    if (!study) {return ""}

    if (study?.patient?.firstName && study?.patient?.lastName) {
        return study.patient.firstName + " " + study.patient.lastName;
    }

    return study?.patient?.name
}