import React, { useEffect, useRef, useState } from 'react';

import { observable, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { ModelCollection } from 'Views/Components/ModelCollection/ModelCollection';
import * as Models from 'Models/Entities';
import moment from 'moment';
import { ApiQueryParams, IOrderByCondition } from 'Views/Components/ModelCollection/ModelAPIQuery';
import { ICollectionHeaderProps } from 'Views/Components/Collection/CollectionHeaders';
import { PaginationQueryOptions } from 'Models/PaginationData';
import { IFilter } from 'Views/Components/Collection/CollectionFilterPanel';
import { makeEnumFetchFunction } from 'Util/EntityUtils';
import { debounce } from 'lodash';
import {ICollectionBulkActionProps, ICollectionItemActionProps} from 'Views/Components/Collection/Collection';

import {Heading} from '@chakra-ui/react'
import axios from "axios";
import {useParams} from "react-router-dom";
import UsaOptionsModal from "../../Components/Modal/UsaOptionsModal";
import {accessLevel} from "../../../Models/Enums";
import {SiteGroupEntity, UserSiteAccessEntity} from "Models/Entities";
import alert from "../../../Util/ToastifyUtils";
import {useNavigate} from "react-router";
import {useQuery} from "@apollo/client";
import {SERVER_URL} from "../../../Constants";

type transformFn<T> = (item: T, name: string) => (string | React.ReactNode);
interface IUserSiteAccessCreateCrudTileProps {
	orderBy?: IOrderByCondition<Models.SiteEntity>;
	perPage?: number;
}

interface User {
	id: string;
	name: string;
	email: string;
}
interface ISearch {
	searchTerm: string;
}

const UserSiteAccessCreateCrudTile = (props: IUserSiteAccessCreateCrudTileProps) => {
	const [search, setSearch] = useState({ searchTerm: '' });
	const [users, setUsers] = useState<User[]>([]);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [selectedModels, setSelectedModels] = useState<Models.SiteEntity[]>([]);
	
	const collectionRef = useRef<ModelCollection<Models.SiteEntity> | null>(null);
	const defaultOrderBy: IOrderByCondition<Models.SiteEntity> = { path: 'created', descending: true };
	const orderBy: IOrderByCondition<Models.SiteEntity> | undefined = observable(defaultOrderBy || props.orderBy);
	const paginationQueryOptions: PaginationQueryOptions = observable(new PaginationQueryOptions());
	const { ids } = useParams();
	const idArray = ids ? ids.split(',') : [];
	const navigate = useNavigate();

	const getUsers = async () => {
		const userPromises = idArray.map(id =>
			axios.get<User>(`/api/entity/UserEntity/${id}`)
		);

		const responses = await Promise.all(userPromises);

		return responses.map(response => response.data);
	}
	
	useEffect(() => {
		const fetchUsers = async () => {
			const data = await getUsers();
			setUsers(data)
		};
		fetchUsers();
	}, [ids]);

	const {loading, data} = useQuery(SiteGroupEntity.fetchSiteGroupFilter())
	if(loading){
		return(<></>)
	}
	const tranformSiteName: transformFn<Models.SiteEntity> = (site: Models.SiteEntity) => site.siteName;
	const transformSiteAddress: transformFn<Models.SiteEntity> = (site: Models.SiteEntity) => site.streetAddress;

	const transformSiteGroup: transformFn<Models.SiteEntity> = (site: Models.SiteEntity) => {
		const matchingSiteGroup = data.siteGroupEntitys.find((group: { id: string | undefined; }) => group.id === site.siteGroupId);

		if (matchingSiteGroup) {
			return matchingSiteGroup.name; 
		}

		return site.siteGroupId;
	}
	

	const getHeaders = (): Array<ICollectionHeaderProps<Models.SiteEntity>> => {
		return [
			{
				name: 'site',
				displayName: 'Site',
				sortable: true,
				transformItem: tranformSiteName,
			},
			{
				name: 'streetAddress',
				displayName: 'Street Address',
				sortable: true,
				transformItem: transformSiteAddress,
			},
			{
				name: 'siteGroup',
				displayName: 'Site Group',
				sortable: true,
				transformItem: transformSiteGroup
			}
		];
	};

	const getActions = () => {
		const tableActions: Array<ICollectionItemActionProps<Models.SiteEntity>> = [];
		
		return tableActions;
	};

	const getBulkActions = (): ICollectionBulkActionProps<Models.SiteEntity>[] => {
		const createBulkAccess: ICollectionBulkActionProps<Models.SiteEntity> = {
			label: `Add User Site Access`,
			showIcon: true,
			icon: 'person-add',
			iconPos: 'icon-left',
			bulkAction: (models, event) => {
				setSelectedModels(models);
				setIsModalOpen(true);
			},
		};
		
		return [createBulkAccess];
	};

	const getMoreParamsFromFilters = (filters: Array<IFilter<Models.SiteEntity>>): ApiQueryParams => {
		const params = {};

		const siteGroupFilter = filters.find(f => f.path === 'siteGroup' && !!f.value1 && (f.value1 as []).length !== 0);
		if (siteGroupFilter) {
			params['siteGroup'] = JSON.stringify(siteGroupFilter.value1);
		}

		return params;
	};

	const onSearchTriggered = (searchTerm: string) => {
		setSearch({ searchTerm });
	};

	const getMoreParams = (filters?: Array<IFilter<Models.SiteEntity>>, filterApplied?: boolean): ApiQueryParams => {
		const filterParams = (filters && filterApplied) ? getMoreParamsFromFilters(filters) : {};

		if (search.searchTerm) {
			filterParams['searchStr'] = search.searchTerm;
		}

		return filterParams;
	};

	const additionalActions = (): React.ReactNode[] => [];

	const getFilters = (): Array<IFilter<Models.SiteEntity>> => {
		let siteGroupOptions = {};

		data.siteGroupEntitys.forEach((siteGroup: { name: any; id: string | number; }) => {
			if (siteGroup.name) {
				siteGroupOptions[siteGroup.id] = siteGroup.name;
			}
		});

		const siteGroupFilter: IFilter<Models.SiteEntity> = {
			path: 'siteGroup',
			comparison: 'equal',
			value1: [],
			value2: undefined,
			active: false,
			displayType: 'enum-combobox',
			displayName: 'Site Group',
			enumResolveFunction: makeEnumFetchFunction(siteGroupOptions),
		};
		
		return [siteGroupFilter];
	};
	
	const handleCloseModal = () => {
		setIsModalOpen(false);
		setSelectedModels([]);
	}
	
	const handleModalConfirm = async (accessLevel: accessLevel, featureSessions: boolean, hl7Messaging: boolean, providerNumber: string) => {
		try {
			const response = await axios.get<{ data: UserSiteAccessEntity[]; count: number }>(
				`${SERVER_URL}/api/entity/UserSiteAccessEntity`,
				{
					params: {
						siteIds: selectedModels.map((model) => model.id),
					},
				}
			);
			
			users.forEach((user) => {
				selectedModels.forEach((site) => {
					const exists = response.data.data.some((entity: UserSiteAccessEntity) =>
						entity.userId === user.id && entity.siteId === site.id
					);

					if (!exists) {
						runInAction(() => {
							const usaEntity = new UserSiteAccessEntity();
							usaEntity.siteId = site.id;
							usaEntity.userId = user.id;
							usaEntity.accessLevel = accessLevel;
							usaEntity.featureSessions = featureSessions;
							usaEntity.hl7MessagingEnabled = hl7Messaging;
							if (providerNumber !== "") {
								usaEntity.providerNumber = providerNumber;
							}
							usaEntity.save();
						});
					}
				});
			});

			alert(`Successfully created User Site Access`, 'success');
		} catch (error) {
			console.error('Error creating entity:', error);
			alert(`Could not create User Site Access`, 'error');
		}
		setIsModalOpen(false);
		setSelectedModels([]);
		navigate(-1)
	}
	
	const getHeadings = () => {
		if(users.length == 1){
			return(<>
					<Heading as={"h4"} mb={0}>Create User Site Access:</Heading>
					<Heading as={"h4"} mt={2}>{users[0].name} - {users[0].email}</Heading>
			</>
			)
		} else {
			return(
				<>
					<Heading as={"h4"} mb={0}>Creating Bulk Site Access:</Heading>
					<Heading as={"h4"} mt={2}>{users.length} users creating</Heading>
				</>
			)
		}
	}

	return (
		<div>
			{getHeadings()}
			<UsaOptionsModal
				isOpen={isModalOpen}
				onClose={handleCloseModal}
				onConfirm={handleModalConfirm}
				users={users}
				models={selectedModels} 
			/>

			<ModelCollection
				ref={collectionRef}
				url={"/api/entity/SiteEntity/AddSites"}
				searchStr={search.searchTerm}
				isApiQuery
				orderBy={orderBy}
				model={Models.SiteEntity}
				headers={getHeaders()}
				actions={getActions()}
				selectableItems={true}
				onSearchTriggered={debounce(onSearchTriggered, 200)}
				perPage={paginationQueryOptions.perPage}
				idColumn="id"
				dataFields={row => ({
					created: moment(row.created).format('YYYY-MM-DD'),
					modified: moment(row.modified).format('YYYY-MM-DD'),
				})}
				getMoreParams={getMoreParams}
				filters={getFilters()}
				additionalActions={additionalActions()}
				selectedBulkActions={getBulkActions()}
				dateFormat="d/m/Y"
				altFormat="d/m/Y"
			/>
		</div>
	);
};

export default inject('store')(observer(UserSiteAccessCreateCrudTile));
