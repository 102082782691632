import { MeasurementEntity } from 'Models/Entities';
import measurementStructure from '../Assets/data/measurementStructure.json';
import us2MeasurementKeys from '../Assets/data/us2MeasurementKeys.json';


export const MeasurementsTemplate = {
	HR: new MeasurementEntity({ name: 'HR' }),
	SBP: new MeasurementEntity({ name: 'SBP' }),
	DBP: new MeasurementEntity({ name: 'DBP' }),
	MMLVIDD: new MeasurementEntity({ name: '(MM)LVIDD' }),
	MMLVIDDIndex: new MeasurementEntity({ name: '(MM)LVIDDIndex' }),
	MMLVIDS: new MeasurementEntity({ name: '(MM)LVIDS' }),
	MMLVIVSD: new MeasurementEntity({ name: '(MM)LVIVSD' }),
	MMLVPWTD: new MeasurementEntity({ name: '(MM)LVPWTD' }),
	MMLVMASScubed: new MeasurementEntity({ name: '(MM)LVMASScubed' }),
	MMLVMI: new MeasurementEntity({ name: '(MM)LVMI' }),
	$2DLVMI: new MeasurementEntity({ name: '(2D)LVMI' }),
	MMLVFSteich: new MeasurementEntity({ name: '(MM)LVFSteich' }),
	MMLVEFteich: new MeasurementEntity({ name: '(MM)LVEFteich' }),
	MMAOROOT: new MeasurementEntity({ name: '(MM)AOROOT' }),
	MMLAAPSD: new MeasurementEntity({ name: '(MM)LAAPSD' }),
	MMRVIDD: new MeasurementEntity({ name: '(MM)RVIDD' }),
	MMTAPSE: new MeasurementEntity({ name: '(MM)TAPSE' }),
	$2DLVIDD: new MeasurementEntity({ name: '(2D)LVIDD' }),
	$2DLVIDDIndex: new MeasurementEntity({ name: '(2D)LVIDDIndex' }),
	$2DLVIDS: new MeasurementEntity({ name: '(2D)LVIDS' }),
	$2DLVIVSD: new MeasurementEntity({ name: '(2D)LVIVSD' }),
	$2DLVPWTD: new MeasurementEntity({ name: '(2D)LVPWTD' }),
	$2DLVMASScubed: new MeasurementEntity({ name: '(2D)LVMASScubed' }),
	$2DLVFSteich: new MeasurementEntity({ name: '(2D)LVFSteich' }),
	$2DLVEFteich: new MeasurementEntity({ name: '(2D)LVEFteich' }),
	$2DLVEDVMODBP: new MeasurementEntity({ name: '(2D)LVEDVMODBP' }),
	$2DLVEDVMODBPIndex: new MeasurementEntity({ name: '(2D)LVEDVMODBPIndex' }),
	$2DLVESVMODBP: new MeasurementEntity({ name: '(2D)LVESVMODBP' }),
	$2DLVSVMODBP: new MeasurementEntity({ name: '(2D)LVSVMODBP' }),
	$2DLVEFMODBP: new MeasurementEntity({ name: '(2D)LVEFMODBP' }),
	$2DLVEDVMODA4C: new MeasurementEntity({ name: '(2D)LVEDVMODA4C' }),
	$2DLVESVMODA4C: new MeasurementEntity({ name: '(2D)LVESVMODA4C' }),
	$2DSVMODA4C: new MeasurementEntity({ name: '(2D)SVMODA4C' }),
	$2DEFMODA4C: new MeasurementEntity({ name: '(2D)EFMODA4C' }),
	$2DLVEDVMODA2C: new MeasurementEntity({ name: '(2D)LVEDVMODA2C' }),
	$2DLVESVMODA2C: new MeasurementEntity({ name: '(2D)LVESVMODA2C' }),
	$2DSVMODA2C: new MeasurementEntity({ name: '(2D)SVMODA2C' }),
	$2DEFMODA2C: new MeasurementEntity({ name: '(2D)EFMODA2C' }),
	$2DPGLSA2C: new MeasurementEntity({ name: '(2D)PGLSA2C' }),
	$2DPGLSA4C: new MeasurementEntity({ name: '(2D)PGLSA4C' }),
	$2DPGLSALAX: new MeasurementEntity({ name: '(2D)PGLSALAX' }),
	$2DPGLS: new MeasurementEntity({ name: '(2D)PGLS' }),
	PWLVIVRT: new MeasurementEntity({ name: '(PW)LVIVRT' }),
	$2DLVOTD: new MeasurementEntity({ name: '(2D)LVOTD' }),
	$2DAOROOT: new MeasurementEntity({ name: '(2D)AOROOT' }),
	$2DAOROOTIndex: new MeasurementEntity({ name: '(2D)AOROOTIndex' }),
	$2DASCAO: new MeasurementEntity({ name: '(2D)ASCAO' }),
	$2DASCAOIndex: new MeasurementEntity({ name: '(2D)ASCAOIndex' }),
	$2DAOARCH: new MeasurementEntity({ name: '(2D)AOARCH' }),
	$2DDESCAO: new MeasurementEntity({ name: '(2D)DESCAO' }),
	$2DRAAREA: new MeasurementEntity({ name: '(2D)RAAREA' }),
	$2DLAAREA: new MeasurementEntity({ name: '(2D)LAAREA' }),
	$2DLAEDVMODBP: new MeasurementEntity({ name: '(2D)LAEDVMODBP' }),
	$2DLAEDVMODBPIndex: new MeasurementEntity({ name: '(2D)LAEDVMODBPIndex' }),
	$2DLAEDVMODAL: new MeasurementEntity({ name: '(2D)LAEDVMODAL' }),
	$2DLAEDVMODALIndex: new MeasurementEntity({ name: '(2D)LAEDVMODALIndex' }),
	$2DRVEDDBASE: new MeasurementEntity({ name: '(2D)RVEDDBASE' }),
	$2DRVEDDMID: new MeasurementEntity({ name: '(2D)RVEDDMID' }),
	$2DRVAREAD: new MeasurementEntity({ name: '(2D)RVAREAD' }),
	$2DRVAREAS: new MeasurementEntity({ name: '(2D)RVAREAS' }),
	$2DRVFAC: new MeasurementEntity({ name: '(2D)RVFAC' }),
	PWLVOTVTI: new MeasurementEntity({ name: 'PW-LVOTVTI' }),
	PWLVOTVMAX: new MeasurementEntity({ name: 'PW-LVOTVMAX' }),
	PWLVOTVMEAN: new MeasurementEntity({ name: 'PW-LVOTVMEAN' }),
	PWLVOTMGRAD: new MeasurementEntity({ name: 'PW-LVOTMGRAD' }),
	PWLVOTPGRAD: new MeasurementEntity({ name: 'PW-LVOTPGRAD' }),
	PWLVOTSV: new MeasurementEntity({ name: 'PW-LVOTSV' }),
	CWAVVTI: new MeasurementEntity({ name: 'CW-AVVTI' }),
	CWAVVMAX: new MeasurementEntity({ name: 'CW-AVVMAX' }),
	CWAVVMEAN: new MeasurementEntity({ name: 'CW-AVVMEAN' }),
	CWAVMGRAD: new MeasurementEntity({ name: 'CW-AVMGRAD' }),
	CWAVPGRAD: new MeasurementEntity({ name: 'CW-AVPGRAD' }),
	CAVAVTI: new MeasurementEntity({ name: 'C-AVAVTI' }),
	CAVAVTIIndex: new MeasurementEntity({ name: 'C-AVAVTIIndex' }),
	CAVAVMAX: new MeasurementEntity({ name: 'C-AVAVMAX' }),
	CAVDI: new MeasurementEntity({ name: 'C-AVDI' }),
	CWARPHT: new MeasurementEntity({ name: 'CW-ARPHT' }),
	PWMVEMAX: new MeasurementEntity({ name: 'PW-MVEMAX' }),
	PWMVAMAX: new MeasurementEntity({ name: 'PW-MVAMAX' }),
	PWMVDECT: new MeasurementEntity({ name: 'PW-MVDECT' }),
	PWMVADUR: new MeasurementEntity({ name: 'PW-MVADUR' }),
	PWMVEARATIO: new MeasurementEntity({ name: 'PW-MVEARATIO' }),
	CWMVMGRAD: new MeasurementEntity({ name: 'CW-MVMGRAD' }),
	CWMVPGRAD: new MeasurementEntity({ name: 'CW-MVPGRAD' }),
	CWMVVMAX: new MeasurementEntity({ name: 'CW-MVVMAX' }),
	CWMVPHT: new MeasurementEntity({ name: 'CW-MVPHT' }),
	CWMVVTI: new MeasurementEntity({ name: 'CW-MVVTI' }),
	CMVAPHT: new MeasurementEntity({ name: 'C-MVAPHT' }),
	CMVAVTI: new MeasurementEntity({ name: 'C-MVAVTI' }),
	PWMRPISARADIUS: new MeasurementEntity({ name: 'PW-MRPISARADIUS' }),
	PWMRPISAALIASVMAX: new MeasurementEntity({ name: 'PW-MRPISAALIASVMAX' }),
	CWMRPISAVMAX: new MeasurementEntity({ name: 'CW-MRPISAVMAX' }),
	CWMRPISAVTI: new MeasurementEntity({ name: 'CW-MRPISAVTI' }),
	CMRPISAERO: new MeasurementEntity({ name: 'C-MRPISAERO' }),
	CMRPISARVOL: new MeasurementEntity({ name: 'C-MRPISARVOL' }),
	PWEPRIMESEP: new MeasurementEntity({ name: 'PW-EPRIMESEP' }),
	PWEPRIMELAT: new MeasurementEntity({ name: 'PW-EPRIMELAT' }),
	PWAPRIMESEP: new MeasurementEntity({ name: 'PW-APRIMESEP' }),
	PWAPRIMELAT: new MeasurementEntity({ name: 'PW-APRIMELAT' }),
	PWEEPRIMERATIOSEP: new MeasurementEntity({ name: 'PW-EEPRIMERATIOSEP' }),
	PWEEPRIMERATIOLAT: new MeasurementEntity({ name: 'PW-EEPRIMERATIOLAT' }),
	PWEEPRIMERATIOAVE: new MeasurementEntity({ name: 'PW-EEPRIMERATIOAVE' }),
	CWTRVMAX: new MeasurementEntity({ name: 'CW-TRVMAX' }),
	CWTVEMAX: new MeasurementEntity({ name: 'CW-TVEMAX' }),
	CWTVPHT: new MeasurementEntity({ name: 'CW-TVPHT' }),
	CWTVMGRAD: new MeasurementEntity({ name: 'CW-TVMGRAD' }),
	CWTRPGRAD: new MeasurementEntity({ name: 'CW-TRPGRAD' }),
	PWRVSPRIME: new MeasurementEntity({ name: 'PW-RVSPRIME' }),
	CWRAP: new MeasurementEntity({ name: 'CW-RAP' }),
	CWRVSP: new MeasurementEntity({ name: 'CW-RVSP' }),
	CEPLAR: new MeasurementEntity({ name: 'C-EPLAR' }),
	PWPVEINARVMAX: new MeasurementEntity({ name: 'PW-PVEINARVMAX' }),
	PWPVEINSVMAX: new MeasurementEntity({ name: 'PW-PVEINSVMAX' }),
	PWPVEINARDUR: new MeasurementEntity({ name: 'PW-PVEINARDUR' }),
	PWPVEINDVMAX: new MeasurementEntity({ name: 'PW-PVEINDVMAX' }),
	PWPVSDRATIO: new MeasurementEntity({ name: 'PW-PVSDRATIO' }),
	PWMVADURPWPVEINARDUR: new MeasurementEntity({ name: 'PWMVADURPWPVEINARDUR' }),
	CWPVVMAX: new MeasurementEntity({ name: 'CW-PVVMAX' }),
	CWPVPGRAD: new MeasurementEntity({ name: 'CW-PVPGRAD' }),
	CWPVMGRAD: new MeasurementEntity({ name: 'CW-PVMGRAD' }),
	CWPREPGRAD: new MeasurementEntity({ name: 'CW-PREPGRAD' }),
	CWPVACCELTIME: new MeasurementEntity({ name: 'CW-PVACCELTIME' }),
	CWPVVTI: new MeasurementEntity({ name: 'CW-PVVTI' }),
	RAVOL: new MeasurementEntity({ name: 'RAVOL' }),
	$2DSTJ: new MeasurementEntity({ name: 'ST Junction' }),
	CWPAEDP: new MeasurementEntity({name: 'CW-PAEDP'}),
};

export const PrecisionMap = {
	HR: 0,
	SBP: 0,
	DBP: 0,
	MMLVIDD: 1,
	MMLVIDDIndex: 1,
	MMLVIDS: 1,
	MMLVIVSD: 1,
	MMLVPWTD: 1,
	MMLVMASScubed: 0,
	MMLVFSteich: 0,
	MMLVEFteich: 0,
	MMAOROOT: 1,
	MMLAAPSD: 1,
	MMRVIDD: 1,
	MMTAPSE: 1,
	MMLVMI: 0,
	$2DLVMI: 0,
	$2DLVIDD: 1,
	$2DLVIDDIndex: 1,
	$2DLVIDS: 1,
	$2DLVIVSD: 1,
	$2DLVPWTD: 1,
	$2DLVMASScubed: 0,
	$2DLVFSteich: 0,
	$2DLVEFteich: 0,
	$2DLVEFvisual: 0,
	$2DLVEDVMODBP: 0,
	$2DLVEDVMODBPIndex: 0,
	$2DLVESVMODBP: 0,
	$2DLVSVMODBP: 0,
	$2DLVEFMODBP: 0,
	$2DLVEDVMODA4C: 0,
	$2DLVESVMODA4C: 0,
	$2DSVMODA4C: 0,
	$2DEFMODA4C: 0,
	$2DLVEDVMODA2C: 0,
	$2DLVESVMODA2C: 0,
	$2DSVMODA2C: 0,
	$2DEFMODA2C: 0,
	$2DPGLSA2C: 1,
	$2DPGLSA4C: 1,
	$2DPGLSALAX: 1,
	$2DPGLS: 1,
	PWLVIVRT: 0,
	$2DLVOTD: 1,
	$2DAOROOT: 1,
	$2DAOROOTIndex: 1,
	$2DASCAO: 1,
	$2DASCAOIndex: 1,
	$2DAOARCH: 1,
	$2DDESCAO: 1,
	$2DRAAREA: 0,
	$2DLAAREA: 0,
	$2DLAEDVMODBP: 0,
	$2DLAEDVMODBPIndex: 0,
	$2DLAEDVMODAL: 0,
	$2DLAEDVMODALIndex: 0,
	$2DRVEDDBASE: 1,
	$2DRVEDDMID: 1,
	$2DRVAREAD: 1,
	$2DRVAREAS: 1,
	$2DRVFAC: 0,
	PWLVOTVTI: 0,
	PWLVOTVMAX: 1,
	PWLVOTVMEAN: 1,
	PWLVOTMGRAD: 0,
	PWLVOTPGRAD: 0,
	PWLVOTSV: 0,
	CWAVVTI: 0,
	CWAVVMAX: 1,
	CWAVVMEAN: 1,
	CWAVMGRAD: 0,
	CWAVPGRAD: 0,
	CAVAVTI: 1,
	CAVAVTIIndex: 1,
	CAVAVMAX: 1,
	CAVDI: 2,
	CWARPHT: 0,
	PWMVEMAX: 2,
	PWMVAMAX: 2,
	PWMVDECT: 0,
	PWMVADUR: 0,
	PWMVEARATIO: 2,
	CWMVMGRAD: 0,
	CWMVPGRAD: 0,
	CWMVVMAX: 1,
	CWMVPHT: 0,
	CWMVVTI: 0,
	CMVAPHT: 1,
	CMVAVTI: 1,
	PWMRPISARADIUS: 2,
	PWMRPISAALIASVMAX: 2,
	CWMRPISAVMAX: 2,
	CWMRPISAVTI: 2,
	CMRPISAERO: 2,
	CMRPISARVOL: 0,
	PWEPRIMESEP: 2,
	PWEPRIMELAT: 2,
	PWAPRIMESEP: 2,
	PWAPRIMELAT: 2,
	PWEEPRIMERATIOSEP: 0,
	PWEEPRIMERATIOLAT: 0,
	PWEEPRIMERATIOAVE: 0,
	CWTRVMAX: 1,
	CWTVEMAX: 2,
	CWTVPHT: 0,
	CWTVMGRAD: 0,
	CWTRPGRAD: 0,
	PWRVSPRIME: 2,
	CWRAP: 0,
	CWRVSP: 0,
	CEPLAR: 2,
	PWPVEINARVMAX: 2,
	PWPVEINSVMAX: 2,
	PWPVEINARDUR: 0,
	PWPVEINDVMAX: 2,
	PWMVADURPWPVEINARDUR: 0,
	PWPVSDRATIO: 2,
	CWPVVMAX: 1,
	CWPVPGRAD: 0,
	CWPVMGRAD: 0,
	CWPREPGRAD: 0,
	CWPAEDP: 0,
	CWPVACCELTIME: 0,
	CWPVVTI: 0,
	RAVOL: 0,
	$2DSTJ: 1,
	BSA: 2,
};

export const DefaultUnitMap = {
	HR: 'bpm',
	SBP: 'mmHg',
	DBP: 'mmHg',
	MMLVIDD: 'cm',
	MMLVIDDIndex: 'cm/m2',
	MMLVIDS: 'cm',
	MMLVIVSD: 'cm',
	MMLVPWTD: 'cm',
	MMLVMASScubed: 'g',
	MMLVFSteich: '%',
	MMLVEFteich: '%',
	MMAOROOT: 'cm',
	MMLAAPSD: 'cm',
	MMRVIDD: 'cm',
	MMTAPSE: 'cm',
	MMLVMI: 'g/m2',
	$2DLVMI: 'g/m2',
	$2DLVIDD: 'cm',
	$2DLVIDDIndex: 'cm/m2',
	$2DLVIDS: 'cm',
	$2DLVIVSD: 'cm',
	$2DLVPWTD: 'cm',
	$2DLVMASScubed: 'g',
	$2DLVFSteich: '%',
	$2DLVEFteich: '%',
	$2DLVEFvisual: '%',
	$2DLVEDVMODBP: 'ml',
	$2DLVEDVMODBPIndex: 'ml/m2',
	$2DLVESVMODBP: 'ml',
	$2DLVSVMODBP: 'ml',
	$2DLVEFMODBP: '%',
	$2DLVEDVMODA4C: 'ml',
	$2DLVESVMODA4C: 'ml',
	$2DSVMODA4C: 'ml',
	$2DEFMODA4C: '%',
	$2DLVEDVMODA2C: 'ml',
	$2DLVESVMODA2C: 'ml',
	$2DSVMODA2C: 'ml',
	$2DEFMODA2C: '%',
	$2DPGLSA2C: '%',
	$2DPGLSA4C: '%',
	$2DPGLSALAX: '%',
	$2DPGLS: '%',
	PWLVIVRT: 'msec',
	$2DLVOTD: 'cm',
	$2DAOROOT: 'cm',
	$2DAOROOTIndex: 'cm/m2',
	$2DASCAO: 'cm',
	$2DASCAOIndex: 'cm/m2',
	$2DAOARCH: 'cm',
	$2DDESCAO: 'cm',
	$2DRAAREA: 'cm2',
	$2DLAAREA: 'cm2',
	$2DLAEDVMODBP: 'ml',
	$2DLAEDVMODBPIndex: 'ml/m2',
	$2DLAEDVMODAL: 'ml',
	$2DLAEDVMODALIndex: 'ml/m2',
	$2DRVEDDBASE: 'cm',
	$2DRVEDDMID: 'cm',
	$2DRVAREAD: 'cm2',
	$2DRVAREAS: 'cm2',
	$2DRVFAC: '%', 
	PWLVOTVTI: 'cm',
	PWLVOTVMAX: 'm/s',
	PWLVOTVMEAN: 'm/s',
	PWLVOTMGRAD: 'mmHg',
	PWLVOTPGRAD: 'mmHg',
	PWLVOTSV: 'ml',
	CWAVVTI: 'cm',
	CWAVVMAX: 'm/s',
	CWAVVMEAN: 'm/s',
	CWAVMGRAD: 'mmHg',
	CWAVPGRAD: 'mmHg',
	CAVAVTI: 'cm2',
	CAVAVTIIndex: 'cm2/m2',
	CAVAVMAX: 'cm2',
	CAVDI: ' ', 
	CWARPHT: 'msec',
	PWMVEMAX: 'm/s',
	PWMVAMAX: 'm/s',
	PWMVDECT: 'msec',
	PWMVADUR: 'msec',
	PWMVEARATIO: ' ',
	CWMVMGRAD: 'mmHg',
	CWMVPGRAD: 'mmHg',
	CWMVVMAX: 'm/s',
	CWMVPHT: 'msec',
	CWMVVTI: 'cm',
	CMVAPHT: 'cm2',
	CMVAVTI: 'cm2',
	PWMRPISARADIUS: 'cm',
	PWMRPISAALIASVMAX: 'm/s',
	CWMRPISAVMAX: 'm/s',
	CWMRPISAVTI: 'cm',
	CMRPISAERO: 'cm2',
	CMRPISARVOL: 'ml',
	PWEPRIMESEP: 'cm/s',
	PWEPRIMELAT: 'cm/s',
	PWAPRIMESEP: 'cm/s',
	PWAPRIMELAT: 'cm/s',
	PWEEPRIMERATIOSEP: ' ',
	PWEEPRIMERATIOLAT: ' ',
	PWEEPRIMERATIOAVE: ' ',
	CWTRVMAX: 'm/s',
	CWTVEMAX: 'm/s',
	CWTVPHT: 'msec',
	CWTVMGRAD: 'mmHg',
	CWTRPGRAD: 'mmHg',
	PWRVSPRIME: 'cm/s',
	CWRAP: 'mmHg',
	CWRVSP: 'mmHg',
	CEPLAR: 'm/s',
	PWPVEINARVMAX: 'm/s',
	PWPVEINSVMAX: 'm/s',
	PWPVEINARDUR: 'msec',
	PWPVEINDVMAX: 'm/s',
	PWMVADURPWPVEINARDUR: 'msec',
	PWPVSDRATIO: ' ',
	CWPVVMAX: 'm/s',
	CWPVPGRAD: 'mmHg',
	CWPVMGRAD: 'mmHg',
	CWPREPGRAD: 'mmHg',
	CWPAEDP: 'mmHg',
	CWPVACCELTIME: 'msec',
	CWPVVTI: 'cm',
	RAVOL: 'ml',
	$2DSTJ: 'cm',
	CWEPLAR: 'm/s',
};

export const getVariableLabel = (key: string): string => key.replace('2', '$2')
	.replace('(', '')
	.replace(')', '')
	.replace('-', '');

export const getValuePrecision = (name: string, value: number): number => {
	if (value === null || value === undefined) {
		return 0;
	}
	const key = getSRKeyFromLabel(name);
	const precision = PrecisionMap[key] || 0;
	return Number(value.toFixed(precision));
};

export const getSRPathLabel = (key: string): string => key.replace('$2D', '(2D)')
	.replace('MM', '(MM)')
	.replace('PWE', 'PW-E')
	.replace('PWA', 'PW-A')
	.replace('PWR', 'PW-R')
	.replace('PWM', 'PW-M')
	.replace('PWL', 'PW-L')
	.replace('PWP', 'PW-P')
	.replace('CW', 'CW-')
	.replace('CAV', 'C-AV')
	.replace('CM', 'C-M');

export const getSRKeyFromLabel = (label: string): string => label
	.replace('(2D)', '$2D')
	.replace('(MM)', 'MM')
	.replace('PW-E', 'PWE')
	.replace('PW-A', 'PWA')
	.replace('PW-R', 'PWR')
	.replace('PW-M', 'PWM')
	.replace('PW-L', 'PWL')
	.replace('PW-P', 'PWP')
	.replace('CW-', 'CW')
	.replace('C-AV', 'CAV')
	.replace('C-M', 'CM');

export const getHumanReadableName = (name: string) => {
	const humanName = measurementStructure[name];
	return humanName ? humanName.name : name;
}

export const getUs2MeasurementName = (id: string) => {
	const humanName = us2MeasurementKeys[id];
	return humanName ? humanName : id;
}

export const getHumanDisplayUnit = (name: string) => {
	const key = getSRKeyFromLabel(name);
	
	return DefaultUnitMap[key];
}
