import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader';
import {
	Flex,
	Box,
	Slider,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	Spinner
} from '@chakra-ui/react';
import { RenderingEngine, Enums, imageLoader, eventTarget, getRenderingEngine } from '@cornerstonejs/core';
import type { Types } from '@cornerstonejs/core';
import { useEffect, useState, useCallback, useImperativeHandle, useRef } from 'react';
import * as cornerstone from '@cornerstonejs/core';
import dicomParser from 'dicom-parser';
import ReactResizeDetector from 'react-resize-detector';
import { ZoomTool, utilities, WindowLevelTool, ToolGroupManager, Enums as csToolsEnums, PanTool } from '@cornerstonejs/tools';
import { Sha256 } from '@aws-crypto/sha256-js';
import { HttpRequest } from '@smithy/protocol-http';
import { SignatureV4 } from '@smithy/signature-v4';
import React from 'react';

cornerstoneDICOMImageLoader.external.cornerstone = cornerstone;
cornerstoneDICOMImageLoader.external.dicomParser = dicomParser;

const config = {
	maxWebWorkers: navigator.hardwareConcurrency || 1,
	startWebWorkersOnDemand: true,
};
cornerstoneDICOMImageLoader.webWorkerManager.initialize(config);

const { MouseBindings } = csToolsEnums;

export const xhrRequest = async (url: string): Promise<ArrayBuffer> => {
	const parsedUrl = new URL(url);
	// @ts-ignore
	const queryParams = Object.fromEntries(parsedUrl.searchParams.entries());

	const signer = new SignatureV4({
		credentials: {
			accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID!,
			secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY!,
		},
		service: 'medical-imaging',
		region: process.env.REACT_APP_AWS_REGION!,
		sha256: Sha256,
	});

	const request = new HttpRequest({
		method: 'GET',
		protocol: 'https:',
		hostname: parsedUrl.hostname,
		path: parsedUrl.pathname,
		query: queryParams,
		headers: {
			host: 'dicom-medical-imaging.ap-southeast-2.amazonaws.com',
			accept: 'application/dicom; transfer-syntax=1.2.840.10008.1.2.4.202',
		},
	});

	const signedRequest = await signer.sign(request);

	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest();
		xhr.open('GET', url, true);
		for (const [key, value] of Object.entries(signedRequest.headers)) {
			if (key.toLowerCase() !== 'host') {
				xhr.setRequestHeader(key, value);
			}
		}
		xhr.responseType = 'arraybuffer';
		xhr.onload = () => {
			if (xhr.status === 200) {
				resolve(xhr.response);
			} else {
				reject(new Error(`Failed to load image: ${xhr.statusText}`));
			}
		};
		xhr.onerror = () => reject(new Error('Network error'));
		xhr.send();
	});
};

interface StressViewportProps {
	url: string;
	studyFileId: string;
	index: number;
	frameRate: number;
	currentFileCallback: (arg0: string, arg1: string)=>void;
	deselectFileCallback: (arg0: string)=>void;
	cachedFileCallback: (arg0: string)=>void;
	playbackReadyCallback: (arg0: string)=>void;
	amPlaying: boolean;
	playbackMultiplier: number;
	viewportHeight: string;
	viewportWidth: string;

}

export interface StressViewerViewportRef {
	updateFramerateSpeed: (arg0: number) => void;
	resetViewport: () => void;
}

const NewStressViewerViewport = React.forwardRef((props: StressViewportProps, ref) => {

	const { url, studyFileId, index, frameRate, currentFileCallback, cachedFileCallback, deselectFileCallback, playbackReadyCallback, amPlaying, playbackMultiplier, viewportHeight, viewportWidth } = props;

	const toolGroupId = `MYSTRESSTOOLGROUP_${studyFileId}`;
	const existingToolGroup = ToolGroupManager.getToolGroup(toolGroupId);
	const [toolGroup, setToolGroup] = useState<any>(existingToolGroup || ToolGroupManager.createToolGroup(toolGroupId));
	const renderingEngineRef = useRef<RenderingEngine | null>(null);
	const [currentFileId, setCurrentFileId] = useState<string>('');
	const [randomId, setRandomId] = useState<string>('');
	const [cachedStudyFiles, setCachedStudyFiles] = useState<string[]>([]);
	const [dynamicFramerate, setDynamicFramerate] = useState<number>(frameRate);
	const [currentFramerate, setCurrentFramerate] = useState<number>(frameRate);
	const [framerate, setFramerate] = useState<number>(0);
	const [numFrames, setNumFrames] = useState<number>(0);

	const [isPlaying, setIsPlaying] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [displaySlider, setDisplaySlider] = useState<boolean>(false);

	const [displayControls, setDisplayControls] = useState<boolean>(true);
	const [viewport1, setViewport1] = useState<Types.IViewport>();

	async function getShortString(input: string) {
		const encoder = new TextEncoder();
		const data = encoder.encode(input);
		const hashBuffer = await crypto.subtle.digest('SHA-256', data);
		const hashArray = Array.from(new Uint8Array(hashBuffer));
		const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
		return hashHex.slice(0, 5);
	}

	useImperativeHandle(ref, () => ({
		updateFramerateSpeed,
		resetViewport,
	}));
	
	const togglePlayback = () => {
		const element = document.querySelector(`#element_stress_${studyFileId}`) as HTMLDivElement;
		if (isPlaying) {
			utilities.cine.stopClip(element);
			setIsPlaying(false);

		} else {
			utilities.cine.playClip(element, {framesPerSecond: dynamicFramerate,loop:true});
			setIsPlaying(true);
		}
	}

	const scrollViewport = (delta: number) => {
		// @ts-ignore
		const currentFrameIndex = viewport1.getTargetImageIdIndex();
		if (!isPlaying && viewport1) {
			if (currentFrameIndex === 0 && delta === -1) {
				utilities.scroll(viewport1, {delta: numFrames - 1, loop: false});
			} else {
				utilities.scroll(viewport1, {delta: delta, loop: false});
			}
		}
	}

	const updateFramerate = (newFramerate: number) => {
		const element = document.querySelector(`#element_stress_${studyFileId}`) as HTMLDivElement;
		if (isPlaying) {
			setFramerate(newFramerate);
			utilities.cine.playClip(element, {framesPerSecond: newFramerate,loop:true});
		} else {
			setFramerate(newFramerate);
		}
	}


	function hasFocus(selector: string) {
		return Array
			.from(document.querySelectorAll(selector))
			.some(function(el){
				return el === document.activeElement
			});
	}

	const startUp = async () => {
		if (renderingEngineRef.current) {
			return;
		}
		const randomId = await getShortString(studyFileId);
		setRandomId(randomId);
		const renderingEngineId = `myRenderingEngine_${randomId}`;
		const renderingEngine = new RenderingEngine(renderingEngineId);
		renderingEngineRef.current = renderingEngine;
		const viewportId = `VIEWPORT_${randomId}`;
		const element = document.querySelector(`#element_stress_${studyFileId}`) as HTMLDivElement;
		element.oncontextmenu = e => e.preventDefault();
		const viewportInput: Types.PublicViewportInput = {
			viewportId,
			type: Enums.ViewportType.STACK,
			element,
			defaultOptions : {
				displayArea : {
					imageArea: [1.01, 1.01]
				}
			}
		}

		renderingEngine.enableElement(viewportInput);

		const imageIds: string[] = [];
		setIsLoading(true);
		cornerstoneDICOMImageLoader.wadouri.dataSetCacheManager.load(url, xhrRequest)
			.then( async (dataSet: any) => {
				setCurrentFileId(studyFileId);
				currentFileCallback(studyFileId, `VIEWPORT_${randomId}`);
				cachedFileCallback(studyFileId);

				cachedStudyFiles.push(studyFileId)
				setCachedStudyFiles(cachedStudyFiles);
				const numFrames = dataSet.intString('x00280008');
				setFramerate(dynamicFramerate);
				setNumFrames(numFrames);
				if (!numFrames) {
					imageIds.push(`wadouri:${url}`);
				} else {
					for (let i = 1; i <= numFrames; i++) {
						const imageId = `wadouri:${url}?frame=${i}`;
						imageIds.push(imageId);
					}
				}
				const viewport = (renderingEngine.getViewport(viewportId) as Types.IStackViewport);

				setViewport1(renderingEngine.getViewport(viewportId));
				await imageLoader.loadAndCacheImages(imageIds, cornerstoneDICOMImageLoader.internal.xhrRequest);
				viewport.setStack(imageIds).then(()=>{
					setIsLoading(false);
					const element = document.querySelector(`#element_stress_${studyFileId}`) as HTMLDivElement;
					utilities.stackContextPrefetch.enable(element);
					viewport.render();

					if (!existingToolGroup) {

						toolGroup.addTool(ZoomTool.toolName);
						toolGroup.addTool(WindowLevelTool.toolName);
						toolGroup.addTool(PanTool.toolName);
						toolGroup.setToolActive(WindowLevelTool.toolName, {
							bindings: [
								{
									mouseButton: MouseBindings.Primary, // Left Click
								},
							],
						});
						toolGroup.setToolActive(ZoomTool.toolName, {
							bindings: [
								{
									mouseButton: MouseBindings.Secondary, // Left Click
								},
							],
						});
						toolGroup.setToolActive(PanTool.toolName, {
							bindings: [
							  {
								mouseButton: MouseBindings.Auxiliary, // Middle Click
							  },
							],
						  });
					}
					toolGroup.addViewport(viewportId, renderingEngineId);

					setIsPlaying(false);
					setDisplaySlider(false);
					setDisplayControls(false);
					playbackReadyCallback(studyFileId);

					setTimeout(()=>{
						renderingEngine.resize(true, false);
					}, 100)

				});

			});
	}

	useEffect(() => {
		startUp();
		return () => {
			deselectFileCallback(`viewport_${randomId}`);
				if (renderingEngineRef.current) {
					const viewportId = `VIEWPORT_${randomId}`;
					renderingEngineRef.current.disableElement(viewportId);
					renderingEngineRef.current.destroy();
					cornerstoneDICOMImageLoader.webWorkerManager.terminate();
				}
			}
	}, []);

	const onResize = (studyFileId: string) => {
		if (renderingEngineRef.current) {
			renderingEngineRef.current.resize();  
		}
		if (viewport1) {
			viewport1.render(); 
		}

	}

	const updateFramerateSpeed = (multiplier: number) => {
		const element = document.querySelector(`#element_stress_${studyFileId}`) as HTMLDivElement;
		if (isPlaying) {
			utilities.cine.stopClip(element);
		} 
		const frameRate = dynamicFramerate * multiplier;
		utilities.cine.playClip(element as HTMLDivElement, {loop:false, framesPerSecond: frameRate});
		setCurrentFramerate(frameRate);
		setIsPlaying(true);
	}

	const resetViewport = () => {
		const renderingEngineId = `myRenderingEngine_${randomId}`;
			const renderingEngine = getRenderingEngine(renderingEngineId);
		if (renderingEngine) {
			const viewport = renderingEngine.getViewport(`VIEWPORT_${randomId}`) as Types.IStackViewport;
			if (viewport) {
				viewport.resetCamera();
				viewport.resetProperties();
				viewport.render();
			}
		}

	}

	useEffect(() => {
		const handleKeyDown = (e: { keyCode: any; }) => {
			const key = e.keyCode;
			if (key == 32 && !hasFocus('input, select, textarea')) {
			}
			if (key == 39) {
				scrollViewport(1);
			}
			if (key == 37) {
				scrollViewport(-1);
			}
		};
		document.addEventListener('keydown', handleKeyDown);
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};

	}, [togglePlayback, scrollViewport]);

	return (
		<Box position='relative' width="100%" height='100%'>

			{isLoading ? <Box position='absolute' left='50%' top='50%' transform='translate(-50%,-50%) scale(3)'><Spinner size='xl' color='blue.500'></Spinner></Box> : ''}

			<ReactResizeDetector
				skipOnMount
				refreshMode="debounce"
				refreshRate={200}
				onResize={() => onResize(currentFileId)}
			>
				<div style={{'height': viewportHeight, 'width': viewportWidth, 'position': 'relative','overflow': 'hidden','marginBottom':'-5px'}}>
					<div id={`element_stress_${studyFileId}`} 
						className='viewport_element' 
						data-framerate={dynamicFramerate} 
						data-currentframerate={currentFramerate}
						data-numframes={numFrames} 
						data-index={index} 
						data-studyfileid={studyFileId}
						data-randomnum={randomId}
						style={{width: '100%', height:'100%', 'top': '0', 'left': '0',position:'absolute', opacity : isLoading ? '0' : '1'}}></div>
				</div>

			</ReactResizeDetector>

			{displaySlider && displayControls ?
				<Flex
					position='absolute'
					bottom='70px'
					left='50%'
					transform='translateX(-50%)'
					background='#373151'
					borderRadius='5px'
					width='200px'
					padding='8px'
					alignItems='center'
					justifyContent='space-between'
					border='1px solid #5686ce'
				>
					<Slider aria-label='slider-ex-1' defaultValue={framerate} min={0} max={120} step={1} onChangeEnd={val =>updateFramerate(val)}>
						<SliderTrack>
							<SliderFilledTrack />
						</SliderTrack>
						<SliderThumb />
					</Slider>
				</Flex>
				: ''
			}
		</Box>
	);
});

export default NewStressViewerViewport;
