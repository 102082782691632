import { action, observable } from 'mobx';
import {
	Model, IModelAttributes, attribute, entity, 
} from 'Models/Model';
import * as Models from 'Models/Entities';
import * as AttrUtils from 'Util/AttributeUtils';
import { IAcl } from 'Models/Security/IAcl';
import {
	getAttributes,
	getCreatedModifiedCrudOptions, getModelName,
} from 'Util/EntityUtils';
import { EntityFormMode } from 'Views/Components/Helpers/Common';
import { CRUD } from '../CRUDOptions';
import { SuperAdministratorScheme } from '../Security/Acl/SuperAdministratorScheme';
import {UserFilterConfigurationEntity} from "../Security/Acl/UserFilterConfigurationEntity";
import {SuperAdminFilterConfigurationEntity} from "../Security/Acl/SuperAdminFilterConfigurationEntity";
import {VisitorsFilterConfigurationEntity} from "../Security/Acl/VisitorsFilterConfigurationEntity";
import {lowerCaseFirst} from "../../Util/StringUtils";
import {gql} from "@apollo/client";

export interface IFilterConfigurationEntityAttributes extends IModelAttributes {
	studyType?: string;
	siteId?: string;
	reportStatus?: string;
	assignedDoctor?: string;
	urgency?: string;

	userId?: string;
	user?: Models.UserEntity | Models.IUserEntityAttributes;
}

@entity('FilterConfigurationEntity', 'Filter Configuration')
export default class FilterConfigurationEntity extends Model implements IFilterConfigurationEntityAttributes {
	public static acls: IAcl[] = [
		new SuperAdministratorScheme(),
		new VisitorsFilterConfigurationEntity(),
		new SuperAdminFilterConfigurationEntity(),
		new UserFilterConfigurationEntity(),
	];

	/**
	 * Fields to exclude from the JSON serialization in create operations.
	 */
	public static excludeFromCreate: string[] = [
	];

	/**
	 * Fields to exclude from the JSON serialization in update operations.
	 */
	public static excludeFromUpdate: string[] = [
	];
	
	@observable
	@attribute()
	@CRUD({
		name: 'Study Type',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public studyType: string;

	@observable
	@attribute()
	@CRUD({
		name: 'Site',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public siteId: string;

	@observable
	@attribute()
	@CRUD({
		name: 'Report Status',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public reportStatus: string;

	@observable
	@attribute()
	@CRUD({
		name: 'Assigned Doctor',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public assignedDoctor: string;

	@observable
	@attribute()
	@CRUD({
		name: 'Urgency',
		displayType: 'textfield',
		order: 10,
		headerColumn: true,
		searchable: true,
		searchFunction: 'like',
		searchTransform: AttrUtils.standardiseString,
	})
	public urgency: string;
	
	@observable
	@attribute()
	@CRUD({
		name: 'User',
		displayType: 'reference-combobox',
		order: 50,
		referenceTypeFunc: () => Models.UserEntity,
	})
	public userId?: string;
	
	@observable
	@attribute({ isReference: true })
	public user: Models.UserEntity;
	

	constructor(attributes?: Partial<IFilterConfigurationEntityAttributes>) {
		super(attributes);
	}

	/**
	 * Assigns fields from a passed in JSON object to the fields in this model.
	 * Any reference objects that are passed in are converted to models if they are not already.
	 * This function is called from the constructor to assign the initial fields.
	 */
	@action
	public assignAttributes(attributes?: Partial<IFilterConfigurationEntityAttributes>) {
		super.assignAttributes(attributes);

		if (attributes) {
			if (attributes.studyType !== undefined) {
				this.studyType = attributes.studyType;
			}
			if (attributes.siteId !== undefined) {
				this.siteId = attributes.siteId;
			}
			if (attributes.reportStatus !== undefined) {
				this.reportStatus = attributes.reportStatus;
			}
			if (attributes.assignedDoctor !== undefined) {
				this.assignedDoctor = attributes.assignedDoctor;
			}
			if (attributes.urgency !== undefined) {
				this.urgency = attributes.urgency;
			}
			if (attributes.userId !== undefined) {
				this.userId = attributes.userId;
			}
			if (attributes.user !== undefined) {
				if (attributes.user === null) {
					this.user = attributes.user;
				} else if (attributes.user instanceof Models.UserEntity) {
					this.user = attributes.user;
					this.userId = attributes.user.id;
				} else {
					this.user = new Models.UserEntity(attributes.user);
					this.userId = this.user.id;
				}
			}
		}
	}

	/**
	 * Additional fields that are added to GraphQL queries when using the
	 * the managed model APIs.
	 */
	public defaultExpands = `
		user {
			${Models.UserEntity.getAttributes().join('\n')}
		}
	`;

	/**
	 * The save method that is called from the admin CRUD components.
	 */
	public async saveFromCrud(formMode: EntityFormMode) {
		const relationPath = {
		};
		return this.save(
			relationPath,
			{
				options: [
					{
						key: 'mergeReferences',
						graphQlType: '[String]',
						value: [
						],
					},
				],
				contentType: 'multipart/form-data',
			},
		);
	}

	public static fetchSingleFilter() {
		const model = new FilterConfigurationEntity();
		const modelName = lowerCaseFirst(getModelName(FilterConfigurationEntity));

		return gql`
			query ${modelName} ($args:[WhereExpressionGraph]) {
				${modelName} (where: $args) {
					${getAttributes(FilterConfigurationEntity).join('\n')}
					${model.defaultExpands}
				}
			}`;
	}
}


/*
 * Retrieve the created and modified CRUD attributes for defining the CRUD views and decorate the class with them.
 */
const [createdAttr, modifiedAttr] = getCreatedModifiedCrudOptions();
CRUD(createdAttr)(FilterConfigurationEntity.prototype, 'created');
CRUD(modifiedAttr)(FilterConfigurationEntity.prototype, 'modified');
