import { useEffect, useState } from 'react';
import { MedicalImagingClient, paginateSearchImageSets, GetImageSetMetadataCommand, GetImageSetMetadataCommandOutput } from '@aws-sdk/client-medical-imaging';
import { ImageEntity } from './useFetchHealthImagingURLs';
import {AsyncFunction, parallel} from 'async';


// @ts-ignore
const client = new MedicalImagingClient({
	region: process.env.REACT_APP_AWS_REGION,
	credentials: {
		accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
		secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
	},
});

let datastoreId = process.env.REACT_APP_DATASTORE_ID || '';

// @ts-ignore
interface CustomGetImageSetMetadataCommandOutput extends GetImageSetMetadataCommandOutput {
	imageSetMetadataBlob?: {
		transformToByteArray: () => Promise<Uint8Array>;
	};
}

interface ImageMetadata {
	url: string;
	imageSetId: string;
	imageFrameId: string;
	sopUID: string;
	sopInstanceUID: string;
	numberOfStages: string;
	stageName: string;
	viewName: string;
	viewNumber: string;
	numberOfViews: string;
	numFrames: string;
	frameRate: string;
	stageNumber: string;
}

// Fetch image set metadata and construct URLs
export const fetchImageSetMetadata = async (studyInstanceUid: string, siteDatastoreId: string | undefined): Promise<ImageMetadata[]> => {
	const paginatorConfig = {
		client,
		pageSize: 50,
	};

	const filters = [
		{
			values: [{ DICOMStudyInstanceUID: studyInstanceUid }],
			operator: 'EQUAL',
		}
	];

	if (siteDatastoreId) {
		datastoreId = siteDatastoreId;
	}

	const commandParams = {
		datastoreId,
		searchCriteria: {
			filters,
		},
	};

	// @ts-ignore
	const paginator = paginateSearchImageSets(paginatorConfig, commandParams);
	let promiseList: AsyncFunction<ImageMetadata, Error>[] = [];
	for await (const page of paginator) {
		if (page.imageSetsMetadataSummaries) {
			console.log(page.imageSetsMetadataSummaries);
			for (const meta of page.imageSetsMetadataSummaries) {
				if (typeof meta.imageSetId === 'string' && meta.DICOMTags && meta.DICOMTags.DICOMSeriesModality !== 'SR') {
					promiseList.push((callback: (err: Error | null, result?: any) => void) => {
						if (typeof meta.imageSetId === 'string') {
							handleLoadImageSet(datastoreId, meta.imageSetId, studyInstanceUid)
								.then((urlList) => {
									callback(null, urlList);
								})
								.catch((err) => {
									callback(err);
								});
						}
					});
				}
			}
		}
	}
	console.log('promiseList: ', promiseList)
	return new Promise((resolve, reject) => {
		parallel(promiseList, (err, results) => {
			console.log(results);
			// @ts-ignore
			resolve([].concat(...results)); 
		});
	});
};

const handleLoadImageSet = async (datastoreId: string, imageSetId: string, studyInstanceUID: string): Promise<ImageMetadata[]> => {
	const params = { datastoreId, imageSetId };
	const command = new GetImageSetMetadataCommand(params);
	const response = await client.send(command) as CustomGetImageSetMetadataCommandOutput;

	if (response.imageSetMetadataBlob) {
		const buffer = await response.imageSetMetadataBlob.transformToByteArray();
		const metadata = new TextDecoder().decode(buffer);
		const parsedMetadata = JSON.parse(metadata);
		const seriesInstanceUIDs: string[] = [];
		const dicomData: any[] = [];
		const series = parsedMetadata.Study.Series;
		for (const seriesKey in series) {
			if (series.hasOwnProperty(seriesKey)) {
				const seriesInstanceUID = series[seriesKey].DICOM.SeriesInstanceUID;
				seriesInstanceUIDs.push(seriesInstanceUID);
				const instances = series[seriesKey].Instances;
				for (const instanceKey in instances) {
					if (instances.hasOwnProperty(instanceKey) && instances[instanceKey].ImageFrames.length) {

						if (instances[instanceKey].DICOM.Modality === 'SR' && instances[instanceKey].DICOM.MediaStorageSOPClassUID !== '1.2.840.10008.5.1.4.1.1.3.1') {    
							continue;
						}
						const sopInstanceUID = instances[instanceKey].DICOM.SOPInstanceUID;
						const numberOfStages = instances[instanceKey].DICOM.NumberOfStages;
						const stageName = instances[instanceKey].DICOM.StageName;
						const stageNumber = instances[instanceKey].DICOM.StageNumber;
						const viewName = instances[instanceKey].DICOM.ViewName;
						const viewNumber = instances[instanceKey].DICOM.ViewNumber;
						const numberOfViews = instances[instanceKey].DICOM.NumberOfViewsInStage;
						const numFrames = instances[instanceKey].DICOM.NumberOfFrames;
						const frameRate = instances[instanceKey].DICOM.RecommendedDisplayFrameRate;

						const imageFrameId = instances[instanceKey].ImageFrames[0].ID;
						const dicomDataItem = {
							'sopInstanceUID' : sopInstanceUID,
							'numberOfStages' : numberOfStages,
							'stageName' : stageName,
							'viewName' : viewName,
							'viewNumber' : viewNumber,
							'numberOfViews' : numberOfViews,
							'numFrames' : numFrames,
							'frameRate' : frameRate,
							'imageFrameId' : imageFrameId,
							'stageNumber' : stageNumber
						};
						let allKeysHaveData = true;
						Object.keys(dicomDataItem).forEach(itemKey => {
							if (!dicomDataItem[itemKey]) {
								allKeysHaveData = false;
							}
						});

						if (allKeysHaveData) {
							dicomData.push(dicomDataItem);
						}
						// if (!instances[instanceKey].DICOM.NumberOfFrames) {
						// 	console.log('no NumberOfFrames')
						// 	console.log(instances[instanceKey].DICOM)
						// }
						// if (!instances[instanceKey].DICOM.RecommendedDisplayFrameRate) {
						// 	console.log('no RecommendedDisplayFrameRate')
						// 	console.log(instances[instanceKey].DICOM)
						// }
					}
				}
			}
		}

		const urls: ImageMetadata[] = [];

		for (const seriesUID of seriesInstanceUIDs) {
			for (let i = 0; i < dicomData.length; i++) {
				const dicomDataItem = dicomData[i];
				const url = `/datastore/${datastoreId}/studies/${studyInstanceUID}/series/${seriesUID}/instances/${dicomDataItem.sopInstanceUID}?imageSetId=${imageSetId}`;
				urls.push({...dicomDataItem, 
					url: 'https://dicom-medical-imaging.ap-southeast-2.amazonaws.com' + url,
					imageSetId: imageSetId,
				});
			}
		}
		return urls;
	}

	throw new Error('No image set URLs found in the response');
};
